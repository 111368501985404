.--cursor-pointer {
  cursor: pointer !important;
}

.--bg-success-dark {
  background-color: #10652c !important;
}

.--bg-danger-dark {
  background-color: #7e1919 !important;
}

.--filter-brighness-5 {
  filter: brightness(0.9) !important;
}

.--bg-alpha-transparent-dark {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.--transition-06 {
  transition: transform 0.6s !important;
}

.--rotate-y-360 {
  transform: rotateY(360deg) !important;
}

.--position-relative {
  position: relative !important;
}

.--text-brightness-half {
  filter: brightness(0.7);
}

.--text-shadow-black {
  text-shadow: 1px 3px rgba(104, 104, 104, 0.7);
}

.--text-underline {
  text-decoration: underline !important;
}

.--position-absolute-top-right-15 {
  position: absolute !important;
  top: 15px !important;
  right: 140px !important;
}

.--position-absolute-top-right {
  position: absolute !important;
  top: 15px !important;
  right: 0 !important;
}

.--height-36 {
  height: 36px !important;
}

.--seperator {
  height: 20px;
  width: 1px;
  background-color: #3c4b64;
  margin: auto 10px;
}

.--text-size-12 {
  font-size: 12px;
}

.--text-size-14 {
  font-size: 14px;
}

.--text-size-16 {
  font-size: 16;
}

.--text-size-18 {
  font-size: 18;
}

.--text-size-20 {
  font-size: 20;
}

.--text-size-22 {
  font-size: 22;
}

.--text-size-24 {
  font-size: 24;
}

.--text-size-26 {
  font-size: 26;
}

.--text-size-28 {
  font-size: 28;
}

.--text-size-30 {
  font-size: 30;
}

.--text-size-32 {
  font-size: 32;
}

.--text-size-20 {
  font-size: 20;
}

.--text-size-34 {
  font-size: 34;
}

.--text-size-36 {
  font-size: 36;
}

.--text-bold-500 {
  font-weight: 500;
}

.--text-bold-600 {
  font-weight: 600;
}

.--text-bold-700 {
  font-weight: 700 !important;
}

.--text-bold-800 {
  font-weight: 800 !important;
}

.--text-bold-900 {
  font-weight: 900 !important;
}

.--color-grey-light {
  color: #3c4b64;
}

span {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
}

button:disabled,
button[disabled='disabled'] {
  cursor: not-allowed;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.badge {
  &.badge-intents {
    background-color: #eae4d9;
    width: auto;
    margin: 0 3px;
    border-radius: 20px;
    color: rgba(0, 0, 21, 0.5);
  }
}

.card-body-listing {
  min-height: calc(100vh - 130px);
}

.card-body {
  position: relative;
  overflow-x: auto;
  // min-height: calc(100vh - 114px);
}
